import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Paper,
  Typography,
  TextField,
  Button,
  CardMedia,
  Alert,
} from '@mui/material'
import { gql, useMutation } from '@apollo/client'
import router from 'next/router'
import { useAppContext } from '../../context/appContext'
import { imageFallback } from '../../constants/Images'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'

// We should think about auth context file....
const APP_SECRET = 'sraigtasparniosparnai'

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    authenticateB2BCustomer(email: $email, password: $password)
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh',
    minHeight: '500px',
    // height: '100%',
    flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: 400,
    width: '100%',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },

  logoBox: {
    width: '100%',
    // height: '100%',
    backgroundSize: 'contain',
    cursor: 'pointer',
    marginBottom: '40px',
  },

  media: {
    minHeight: '50px',
    maxHeight: '150px',
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },

  alert: {
    marginTop: '20px',
  },
}))

const LoginPage = () => {
  //-----

  const { t } = useTranslation(['common'])

  const emailInputLabel = t('email-input-label')
  const passwordInputLabel = t('password-input-label')
  const b2bLoginLabel = t('b2b-login-label')
  const loginString = t('login-string')
  const loginErrorString = t('login-error-message')

  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState(false)

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const [loginMutation] = useMutation(LOGIN_MUTATION, {
    onCompleted: (data) => {
      // Handle the successful login response
      const token = data.authenticateB2BCustomer

      if (token) {
        //   console.log('token :>> ', token)
        localStorage.setItem('furnisystems_token', `${token}`)
        //   console.log('Token set to local storage')

        // Store the token (e.g., in cookies or local storage)
        console.log('Login successful')
        setLoginError(false)
        router.push('/') // Redirect to the dashboard page
      } else {
        setLoginError(true)
        console.log('Token is null...')
      }
    },
    onError: (error) => {
      // Handle any errors that occur during the login mutation
      console.log('Login failed:', error.message)
      setLoginError(true)
    },
  })

  const handleSubmit = (event) => {
    event.preventDefault()

    loginMutation({
      variables: { email, password },
    })
  }

  const appContext = useAppContext()
  const shopSettings = appContext ? appContext.shopSettings : null
  const siteLogoSrc = shopSettings
    ? shopSettings.default_manufacturer?.logo_image
      ? shopSettings.default_manufacturer.logo_image.src_xs ??
        shopSettings.default_manufacturer.logo_image.src
      : imageFallback
    : imageFallback

  return (
    <div className={classes.root}>
      <div className={classes.logoBox}>
        <Link prefetch={false} href="/">
          <CardMedia image={siteLogoSrc} className={classes.media} />
        </Link>
      </div>

      <Paper className={classes.paper}>
        <Typography variant="h5" component="h1">
          {b2bLoginLabel}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            label={emailInputLabel}
            type="email"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            className={classes.textField}
            required
          />
          <TextField
            label={passwordInputLabel}
            type="password"
            variant="outlined"
            value={password}
            onChange={handlePasswordChange}
            className={classes.textField}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            {loginString}
          </Button>

          {loginError && (
            <Alert className={classes.alert} severity="error">
              {loginErrorString}
            </Alert>
          )}
        </form>
      </Paper>
    </div>
  )
}

export default LoginPage
