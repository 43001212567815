import { createContext, useContext } from "react";

const AboutContext = createContext(null);

export function AboutContextWrapper({ data, locale, children }) {
  return (
    <AboutContext.Provider value={{ data, locale }}>
      {children}
    </AboutContext.Provider>
  );
}

export function useAboutContext() {
  return useContext(AboutContext);
}
