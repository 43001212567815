import { Box, Button, Container, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PhoneIcon from '@mui/icons-material/Phone'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import { LanguageSwitch } from '../Utils'
import { useShopContext } from '../../../context'
import { useAppContext } from '../../../context/appContext'
import { E } from '../ProductPageElements/AdvancedProductElements/SofaDrawingElements/SofaElements'
import router from 'next/router'

const useStyles = makeStyles(
  (theme: Theme) => ({
    box: {
      backgroundColor: theme.palette.background.footer,
      height: '2.5rem',
      zIndex: 1201,
    },
    container: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
      },
    },
    whiteTypography: {
      color: '#fff',
    },
    marginRight: {
      marginRight: theme.spacing(1),
    },
    leftLinksContainer: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    rightLinksContainer: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      color: '#fff',
    },
  }),
  { name: 'MuiHeaderTopBannerComponent' },
)

export default function HeaderTopBannerForLogin() {
  const { t, i18n } = useTranslation(['common'])

  const classes = useStyles() as any

  const { shopPhoneNumber } = useShopContext()

  const appContext = useAppContext()
  const b2bToken = appContext.b2bToken

  return (
    <div className={classes.box}>
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.leftLinksContainer}></div>

        <div className={classes.rightLinksContainer}>
          {/* <PhoneIcon
            className={clsx(classes.whiteTypography, classes.marginRight)}
          /> */}

          {shopPhoneNumber && !b2bToken && (
            <>
              <PhoneIcon
                className={clsx(classes.whiteTypography, classes.marginRight)}
              />
              <Typography variant="subtitle2">
                <a
                  href={`tel:${shopPhoneNumber}`}
                  style={{ color: '#fff', marginRight: '1rem' }}
                >
                  {shopPhoneNumber}
                </a>
              </Typography>
            </>
          )}

          <LanguageSwitch />
        </div>
      </Container>
    </div>
  )
}
