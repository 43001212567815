import { Container } from '@mui/material'
import { GetServerSideProps, GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import React from 'react'
import { addApolloState, initializeApollo } from '../lib/apolloClient'
import { HeaderTopBanner } from '../src/components/DesktopElements/HeaderElements'
import HeaderTopBannerForLogin from '../src/components/DesktopElements/HeaderElements/HeaderTopBannerForLogin'
import { AboutPage } from '../src/components/Modules'
import LoginPage from '../src/components/Modules/LoginPage'
import NotFound from '../src/components/Modules/NotFound'
import LayoutSecondary from '../src/constants/LayoutSecondary'
import { AboutContextWrapper } from '../src/context/aboutContext'
import { AppContextWrapper } from '../src/context/appContext'
import {
  GET_PARENT_CATEGORIES,
  GET_PARENT_CATEGORIES_NON_RECURSIVE,
} from '../src/operations/queries'
import { GET_ABOUT_US_PAGE } from '../src/operations/queries/aboutUsPageQueries'
import { GET_CONTACT_US_PAGE } from '../src/operations/queries/contactUsPageQueries'
import { APP_SHOP_SETTINGS } from '../src/operations/queries/_appQueries'
import { sortCategories } from '../src/utilityFunctions/categoryUtils'
import { defaultMetaTags } from '../src/utilityFunctions/metaUtils/defaultMetaTags'
import { useShopSettingsInit } from '../src/utilityFunctions/useShopSettingsInit'

function Login({
  locale,
  locales,
  defaultLocale,
  _nextI18Next,
  shopSettingsData,
  categoriesData,
  ...props
}) {
  const { t, i18n } = useTranslation(['common'])
  const { initialI18nStore } = _nextI18Next

  // TODO ADD LOGIN PAGE PERMALINK IN TRANSLATE FILES
  const pageName = t('login-page')
  useShopSettingsInit(shopSettingsData.data.findFirstShopSetting)
  const shopSettings = { ...shopSettingsData.data.findFirstShopSetting }

  // console.log('shopSettings :>> ', shopSettings)

  const b2bEnabled = shopSettings.b2b_enabled

  return (
    <>
      {defaultMetaTags({
        shopSettingsData,
        locale,
        locales,
        defaultLocale,
        initialI18nStore,
        pageName,
      })}
      <AppContextWrapper locale={locale} shopSettings={shopSettings}>
        <AboutContextWrapper data={{}} locale={locale}>
          {/* <LayoutSecondary categoriesData={categoriesData} locale={locale}> */}
          <HeaderTopBannerForLogin />
          {b2bEnabled ? (
            <Container maxWidth="xl">
              <LoginPage />
            </Container>
          ) : (
            <Container maxWidth="xl">
              <NotFound />
            </Container>
          )}

          {/* </LayoutSecondary> */}
        </AboutContextWrapper>
      </AppContextWrapper>
    </>
  )
}

//* ============ PROPS

export const getStaticProps: GetStaticProps = async ({
  params,
  locale,
  locales,
  defaultLocale,
}) => {
  const apolloClient = initializeApollo()
  const SHOP_ID = JSON.parse(process.env.NEXT_PUBLIC_SHOP_ID)

  // console.log('params --->', params)x

  const shopSettingsData = await apolloClient.query({
    query: APP_SHOP_SETTINGS,
    variables: {
      SHOP_ID,
      isHomepage: false,
      isProductPage: false,
    },
  })

  const categoriesDataQuery = await apolloClient.query({
    query: GET_PARENT_CATEGORIES_NON_RECURSIVE,
    variables: {
      language: locale,
    },
  })
  let allCategories = [
    ...JSON.parse(JSON.stringify(categoriesDataQuery.data.findManyCategory)),
  ]
  const sortedByMenuOrder = allCategories.sort(
    (a, b) => a.menu_order - b.menu_order,
  )
  const sortedCategories = sortCategories(sortedByMenuOrder)
  // const flatCategories = flattenCategories(sortedCategories)
  let categoriesData = { ...JSON.parse(JSON.stringify(categoriesDataQuery)) }
  categoriesData.data.findManyCategory = sortedCategories
  // ---------------- END  CATEGORY =============

  return addApolloState(apolloClient, {
    props: {
      locale,
      locales,
      defaultLocale,
      categoriesData,
      ...(await serverSideTranslations(locale, ['common'])),
      shopSettingsData,
    },
    revalidate: 5,
  })
}

export default Login
